@mixin desktop {
  @media only screen and (min-width: $bp-desktop + 1) {
    @content;
  }
}


@mixin tablet {
  @media only screen and (min-width: $bp-mobile + 1) and (max-width: $bp-desktop) {
    @content;
  }
}


@mixin mobile {
  @media only screen and (max-width: $bp-mobile) {
    @content;
  }
}

@mixin tablet_and_desktop {
  @media only screen and (min-width: $bp-mobile + 1) {
    @content;
  }
}


@mixin card {
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.17);
}
