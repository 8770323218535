.globalHeader {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.17);

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 40px;
    
    @include mobile {
      padding: 0 10px;
    }
  }

  &__bottom {
    height: 0;
    background: $color-gray;
    overflow: hidden;
    transition: all 0.2s;
    
    &--show {
      height: 50px;
    }

    .globalNavi {
      width: 100%;
      max-width: 1300px;
      height: 100%;
      margin: 0 auto;
      padding: 0 40px;
      
      @include mobile {
        padding: 0 10px;
      }
      
      &__list {
        display: flex;
        height: 100%;
        
        &__item {
          position: relative;
          
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 8px);
            left: 0;
            width: 1px;
            height: 16px;
            border-left: 1px solid #fff;
          }
          
          a {
            display: flex;
            align-items: center;
            height: 100%;
            line-height: 1;
            font-size: 1.5rem;
            color: #fff;
            padding: 0 24px;
            transition: all 0.3s;
            
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  &--clone {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: all 0.3s;
  }

  &--show {
    transform: translateY(0);
  }

  &__logo {
    height: 100%;

    a {
      display: flex;
      align-items: center;
      height: 50px;
      color: #555;
      font-size: 2.4rem;
    }
  }
}

.globalNavi {

  &__button {
    // デフォルトスタイル解除
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    width: 50px;
    height: 50px;
    background: $color-gray;

    &__inner {
      position: relative;
      width: 20px;
      height: 14px;

      span {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 1px;
        transition: all 0.2s;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: calc(50% - 1px);
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-child(3) {
          bottom: 0;
        }
      }

      &--active {
        span {
          &:nth-child(1) {
            top: calc(50% - 1px);
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            width: 0;
          }

          &:nth-child(3) {
            bottom: calc(50% - 1px);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
