.globalFooter {
  background: $color-gray;
  color: #fff;
  
  @include mobile {
    margin-top: 20px;
  }

  &__copy {
    text-align: center;
    padding: 20px 0 30px 0;
  }

  &__contact {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    padding: 40px;

    @include mobile {
      padding: 20px;
    }

    &__item {

      &:not(:first-child) {
        margin-left: 100px;
      }
    }

    &__header {}
  }

  &__sns {
    display: flex;
    flex-flow: column nowrap;

    &__item {
      position: relative;
      width: 44px;
      height: 44px;
      margin-top: 16px;
      padding: 10px;

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00aced;
        border-radius: 50%;
      }
    }
  }
}


.globalNavi--footer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  
  .globalNavi {
    
    &__list {

      &__item {
        position: relative;

        &:not(:first-child) {

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: calc(50% - 8px);
            left: 0;
            width: 1px;
            height: 16px;
            border-left: 1px solid #fff;
          }
        }

        a {
          display: block;
          color: #fff;
          font-size: 1.4rem;
          padding: 14px 20px;
          transition: all 0.2s;
          
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}


.pageTop {
  position: relative;
  display: block; 
  width: 50px;
  height: 50px;
  background: $color-gray;
  
  @include mobile {
    width: 40px;
    height: 40px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    left: 50%;
    width: 2px;
    height: 14px;
    background: #fff;
    border-radius: 2px;
    transform: rotate(45deg) translateX(1px);
    transform-origin: top left;
    
    @include mobile {
      height: 10px;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    left: 50%;
    width: 2px;
    height: 14px;
    background: #fff;
    border-radius: 2px;
    transform: rotate(-45deg) translateX(-1px);
    transform-origin: top right;
    
    @include mobile {
      height: 10px;
    }
  }

  &__container {
    display: none;
    justify-content: flex-end;
    position: fixed;
    z-index:  999;
    bottom: 135px;
    right: 0;
  }
}