.article {
  position: relative;
  background: #fff;

  @include tablet_and_desktop {
    @include card;
  }

  &__content {
    padding: 0 20px 20px 20px;
    
    @include mobile {
      padding: 0 10px 10px 10px;
    }
  }

  &__list {

    &__item {

      &:not(:first-child) {
        margin-top: 40px;

        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }

  &__visual {
    background: #000;
    
    @include mobile {
      height: 220px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--anime {
      overflow: hidden;

      img {
        display: block;
        transition-duration: 0.3s;

        &:hover {
          transform: scale(1.1);
          transition-duration: 0.3s;
          opacity: 0.6;
        }
      }
    }

    &--dark {

      img {
        opacity: 0.7;  
      }
    }

    &--new {

      &::before {
        content: "NEW";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1;
        padding: 10px 14px;
        background: rgba(0,0,0,0.6);
      } 
    }
  }

  &__title {
    display: inline-block;
    position: relative;
    font-size: 2.6rem;
    margin-top: 30px;
    
    @include mobile {
      margin-top: 20px;
      font-size: 2.4rem;
    }
    
    a {
      color: $color-gray;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    align-items: baseline;
    font-size: 1.6rem;
    margin-top: 20px;

    @include mobile {
      flex-wrap: wrap;
      font-size: 1.4rem;
    }
  }

  &__time {
    margin-right: 20px;

    @include mobile {
      width: 100%;
      
    }
  }

  &__caption {
    width: 100%;
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 1.6;
    
    @include mobile {
      margin-top: 16px;
      font-size: 1.4rem;
      line-height: 1.6;
    }

    a {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &__text {

    h3 {
      display: inline-block;
      line-height: 1;
      font-size: 2rem;
      color: $color-theme;
      border: 3px solid $color-theme;
      border-radius: 3px;
      margin-top: 40px;
      padding: 8px 10px;
      
      @include mobile {
        font-size: 1.8rem;
      }
    }

    h4 {
      position: relative;
      line-height: 1;
      font-size: 1.8rem;
      margin-top: 40px;
      padding-left: 18px;
      
      @include mobile {
        font-size: 1.6rem;
      }

      &:before {
        display: block;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        content: '';
        width: 12px;
        height: 12px;
        border: 3px solid $color-theme;
        border-radius: 50%;
      }
    }

    h5 {
      position: relative;
      line-height: 1;
      font-size: 1.6rem;
      margin-top: 30px;
      
      @include mobile {
        font-size: 1.4rem;
      }
    }

    p {
      width: 100%;
      margin-top: 20px;
      font-size: 1.6rem;
      line-height: 1.6;
      
      @include mobile {
        margin-top: 16px;
        font-size: 1.4rem;
        line-height: 1.6;
      }
    }

    a {
      display: inline-block;
      font-size: 1.6rem;
      color: $color-theme;
      line-height: 1;
      border-bottom: 1px solid $color-theme;
      transition: all 0.3s;
      
      @include mobile {
        font-size: 1.4rem;
      }
      
      &[target="_blank"] {
        position: relative;
        padding-right: 16px;
        
        &::before {
          content: '';
          display: block;
          position: absolute;
          right: 3px;
          bottom: 5px;
          width: 6px;
          height: 6px;
          border: 1px solid $color-theme;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 6px;
          bottom: 2px;
          width: 6px;
          height: 6px;
          border: 1px solid $color-theme;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    hr {
      border-top: 1px solid #ccc;
      margin-top: 20px;
    }

    blockquote {
      border-left: 3px solid $color-theme;
      border-right: 3px solid $color-theme;
      border-radius: 3px;
      margin-top: 30px;
      
      &::before {
        display: block;
        font-family: 'Font Awesome 5 Free';
        content: '\f10d';
        font-weight: bold;
        font-size: 1.8rem;
        color: #fff;
        background: $color-theme;
        padding: 16px 0 16px 20px;
      }

      &::after {
        display: block;
        font-family: 'Font Awesome 5 Free';
        content: '\f10e';
        font-weight: bold;
        font-size: 1.8rem;
        color: #fff;
        text-align: right;
        background: $color-theme;
        padding: 16px 16px 16px 0;
      }
      
      img {
        margin-top: 0;
      }
      
      p {
        margin-top: 0;
      }

      cite {
        font-style: normal;
      }
      
      .BQSrc {
        text-align: right;
        padding: 16px;
      }
    }
  }
}


.moreLink {
  font-size: 1.5rem;

  &__container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    
    a {
      padding: 10px;
      transition: all 0.3s;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.post-categories {
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    margin-left: 0;
    margin-top: 10px;
  }

  li {
    margin: 0 10px 10px 0;

    @include mobile {
      white-space: nowrap;
    }

    a {
      display: block;
      position: relative;
      font-size: 1.3rem;
      color: #fff;
      line-height: 2.4rem;
      background: $color-gray;
      border-radius: 2px;
      padding: 2px 10px 2px 20px;

      &::before {
        content: "#";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
      }

      transition: all 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}


.pager {
  display: flex;
  flex-wrap: wrap;
  border-radius: 3px;
  overflow: hidden;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }

  &__item {
    
    .current {
      background: $color-theme;
    }

    a,span {
      display: block;
      line-height: 1;
      font-size: 1.4rem;
      color: #fff;
      padding: 14px;
      background: $color-gray;
    }

    a {
      transition: all 0.2s;
      
      &:hover {
        opacity: 0.7;
      }
    }

    // &--dot {
    //   display: flex;
    //   align-items: flex-end;
    // }
  }
}

.nextNavi{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  border-radius: 3px;
  overflow: hidden;
  
  @include mobile {
    padding: 0 10px;
  }
  
  &--noPrev {
    justify-content: flex-end;
    
    .nextNavi__item {
      border-radius: 3px;
      overflow: hidden;
    }
  }

  &--noNext {
    
    .nextNavi__item {
      border-radius: 3px;
      overflow: hidden;
    }
  }
  
  &__item {
    display: flex;
    width: 50%;
    
    a{
      display: flex;
      width: 100%;
      align-items: center;
      line-height: 1;
      color: #fff;
      font-size: 1.4rem;
      background: $color-gray;
      padding: 14px;
      transition: all 0.2s;
      
      &:hover {
        opacity: 0.7;
      }
    }

    &--prev {

      a {

        &::before {
          content: '«';
          display: inline-block;
        }
      }
    }

    &--next {

      a{
        display: inherit;
        text-align: right;
        
        &::after {
          content: '»';
          display: inline-block;
        }
      }
    }
  }
}

.homeLink {
  display: inline-block;
  font-size: 1.6rem;
  color: #fff;
  background: $color-gray;
  border-radius: 3px;
  margin-top: 20px;
  padding: 8px 20px;
  transition: all 0.2s;
  
  &:hover {
    opacity: 0.7;
  }
}


.contactForm {
  
  &--responsive {
    max-width: 100%;
  }
}


.wpcf7-submit {
  // デフォルトスタイル削除
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
  
  font-size: 1.6rem;
  color: #fff;
  background: $color-gray;
  padding: 6px 10px;
  border-radius: 3px;
  transition: all 0.2s;
}