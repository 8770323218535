@font-face {
  font-family: YuGothicM;
  src: local("Yu Gothic Medium");
}

* {
  box-sizing: border-box;
}

html {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eaeaea;
  font-family:-apple-system,BlinkMacSystemFont,"Helvetica Neue","游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN",メイリオ,Meiryo,sans-serif;
  color: #555;
  font-size: 62.5%;
}

h1,h2,h3,h4,h5 {
  font-weight: bold;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

li {
  list-style: none;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: 60px auto 0;
  padding: 40px;

  @include desktop {
    flex-wrap: nowrap;
  }

  @include mobile {
    margin: 60px 0 0 0;
    padding: 0;
  }
}

.main {
  width: 100%;

  @include desktop {
    width: calc(100% - 300px);
    padding: 0 40px 0 0;
  }

  @include mobile {
    padding: 0;
  }
}

.aside {
  width: 100%;

  @include desktop {
    width: 300px;
    height: 100%;
  }
}
