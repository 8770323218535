.aside {
  margin-top: 40px;

  @include desktop {
    margin-top: 0;
  }

  @include mobile {
    margin-top: 30px;
    padding: 0 10px;
  }

  &__list {
    @include tablet {
      display: flex;
    }

    &__item {
      @include tablet {
        width: 50%;
        
        &:nth-child(odd) {
          padding-right: 10px;
        }

        &:nth-child(even) {
          padding-left: 10px;
        }

        &:nth-child(n + 3) {
          margin-top: 20px;
        }
      }

      &:not(:first-child) {
        margin-top: 40px;
        
        @include tablet {
          margin-top: 0;
        }
        
        @include mobile {
          margin-top: 20px;
        }
      }
    }
  }

  &Menu {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;

    &__header {
      line-height: 1;
      font-size: 1.6em;
      color: #fff;
      font-weight: normal;
      background: $color-gray;
      padding: 14px 18px;
      
      @include mobile {
        padding: 14px;
      }
    }

    &__content {
      padding: 18px;
      
      @include mobile {
        padding: 14px;
      }
    }
  }
}

.popularPost {

  &__list {

    &__item {
      display: flex;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  &__visual {
    width: 134px;
    background: #000;
    
    img {
      max-width: 100%;
      transition: all 0.3s;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__title {
    width: calc(100% - 134px);
    padding-left: 10px;
    
    a {
      font-size: 1.4rem;
      font-weight: normal;
      transition: all 0.2s;
      
      &:hover {
        opacity: 0.7;;
      }
    }
  }
}

.category {

  &__list {

    li {

      a {
        display: block;
        align-items: center;
        position: relative;
        font-size: 1.5rem;
        color: $color-gray;
        padding: 8px 10px;
        transition: 0.2s;

        &:hover {
          background: #e4e4e4;
        }
      }
    }
  }
}